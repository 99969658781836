import { Colors, H3, H4, VerticalGroup } from "@saysom/shared"
import { useCallback, useRef, useState } from "react"
import DirectionSwitch from "../directionSwitch"
import CantileverGroup from "./cantileverGroup"
import HowToUseVideo from "./howToUseVideo"

const HowItWorksVideo = () => {
	const video = useRef<HTMLVideoElement>(null!)

	const [part, setPart] = useState(0)

	const onTimeUpdate = useCallback(() => {
		if (video.current) {
			if (video.current.currentTime > 15 && video.current.currentTime <= 20) {
				setPart(1)
			} else if (video.current.currentTime > 20) {
				setPart(2)
			} else {
				setPart(0)
			}
		}
	}, [])

	const scrubTo = useCallback((seconds) => {
		if (video.current) {
			video.current.currentTime = seconds

			if (video.current.paused) {
				video.current.play()
			} else {
				video.current.pause()
				video.current.play()
			}
		}
	}, [])

	return (
		<DirectionSwitch>
			<CantileverGroup>
				<HowToUseVideo
					background={Colors.flowy}
					source="communication"
					ref={video}
					onTimeUpdate={onTimeUpdate}
				/>
			</CantileverGroup>

			<VerticalGroup flex={1} className="space-y-4 py-2.5 justify-around">
				<div className="space-y-2.5">
					<h3 className="font-semibold text-xl">
						SaySom is 100% participant centric.
					</h3>
					<H4 style={{ opacity: 0.7, textAlign: "justify" }}>
						We are convinced that networking is only fun when participants
						decide for themselves who they want to talk to. No breakout-rooms or
						blind calling.
					</H4>
				</div>

				<ul className="justify-self-end">
					<li
						className="flex flex-row items-center space-x-12 cursor-pointer"
						onClick={() => scrubTo(0)}
					>
						<div className="border-black dark:border-white border-2 rounded-full p-0.5 border-opacity-10">
							<div
								className={`w-4 h-4 rounded-full bg-black dark:bg-white ${
									part >= 0 ? "bg-opacity-20" : "bg-opacity-0"
								} transition-all`}
							/>
						</div>
						<H3>Spatial audio</H3>
					</li>
					<li className="bg-black dark:bg-white bg-opacity-20 h-4 w-1 m-2.5 rounded-full" />
					<li
						className="flex flex-row items-center space-x-12 cursor-pointer"
						onClick={() => scrubTo(15)}
					>
						<div className="border-black dark:border-white border-2 rounded-full p-0.5 border-opacity-10">
							<div
								className={`w-4 h-4 rounded-full bg-black dark:bg-white ${
									part >= 1 ? "bg-opacity-20" : "bg-opacity-0"
								} transition-all`}
							/>
						</div>
						<H3>Dynamic groups</H3>
					</li>
					<li className="bg-black dark:bg-white bg-opacity-20 h-4 w-1 m-2.5 rounded-full" />
					<li
						className="flex flex-row items-center space-x-12 cursor-pointer"
						onClick={() => scrubTo(20)}
					>
						<div className="border-black dark:border-white border-2 rounded-full p-0.5 border-opacity-10">
							<div
								className={`w-4 h-4 rounded-full bg-black dark:bg-white ${
									part >= 2 ? "bg-opacity-20" : "bg-opacity-0"
								} transition-all`}
							/>
						</div>
						<H3>Private groups</H3>
					</li>
				</ul>
			</VerticalGroup>
		</DirectionSwitch>
	)
}

export default HowItWorksVideo
