import { H1 } from "@saysom/shared"
import React from "react"
import { InnerContainer } from "../../body/body_style"
import { Banner, BannerContainer } from "./featureBanner_style"

const FeatureBanner = () => {
	return (
		<>
			<InnerContainer style={{ alignItems: "center", marginTop: "100px" }}>
				<H1 className="bg-flowy-text bg-clip-text text-transparent ">
					Unmatched Featureset
				</H1>

				<h3>And even more is yet so close to become reality</h3>
			</InnerContainer>
			<BannerContainer>
				<Banner />
			</BannerContainer>
		</>
	)
}

export default FeatureBanner
