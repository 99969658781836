import { Shadows } from "@saysom/shared"
import styled from "styled-components"

export const BannerContainer = styled.div`
	width: 100%;
	overflow: hidden;
	margin-top: 80px;
`

export const Banner = styled.div`
	background: url("/assets/images/features.svg") repeat-x;
	height: 420px;
	width: calc(1834px * 3);
	animation: slide 60s linear infinite;
	filter: drop-shadow(${Shadows.normal});

	@keyframes slide {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(-1834px);
		}
	}

	@keyframes slideMobile {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(-1467px);
		}
	}

	@media (max-width: 768px) {
		height: 336px;
		width: calc(1467px * 3);
		animation: slide 60s linear infinite;
	}
`
