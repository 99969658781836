import React from "react"
import { Container, ImageContainer } from "./spaceCard_style"

const SpaceCard = () => {
	return (
		<Container>
			<ImageContainer className="p-1">
				<video
					muted
					autoPlay
					loop
					playsInline
					className="rounded-md md:rounded-lg w-full max-w-5xl"
					preload="auto"
					poster="/assets/images/intro.jpg"
				>
					<source src={`/assets/videos/intro.mp4`} type="video/mp4" />
				</video>
				{/* <CustomImage src={src} srcSet={srcSet} /> */}
			</ImageContainer>
		</Container>
	)
}

export default SpaceCard
