import styled from "styled-components"
import { PLarge } from "../../../styles"

export const Icon = styled.a`
	border-radius: 100px;
	min-width: 100px;
	max-width: 100px;
	height: 100px;
	background-color: white;
	cursor: pointer;

	@media (max-width: 768px) {
		min-width: 70px;
		max-width: 70px;
		height: 70px;
	}
`

export const IconImg = styled.img`
	filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.16));
	width: 100%;
`

export const MessageContainer = styled.div<{ left?: boolean }>`
	padding: 10px 12px;
	background-color: rgba(100, 100, 100, 0.05);
	border-radius: 20px;
	display: flex;
	align-items: center;
	backdrop-filter: blur(10px);
	margin-right: ${(props) => (props.left ? "110px" : "0px")};
	margin-left: ${(props) => (props.left ? "0px" : "110px")};

	@media (max-width: 764px) {
		margin-right: 0px;
		margin-left: 0px;
		text-shadow: grey 0px 0px 4px;
	}
`
export const CompanyName = styled(PLarge)<{ left?: boolean }>`
	background-color: white;
	border-radius: 100px;
	padding: 8px 12px;
	color: #6867ff;
	margin-bottom: 14px;
	cursor: pointer;
`

export const DottedBackground = styled.div`
	background: url("/assets/images/dots.svg") repeat;
	background-size: fill;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: 0;
	mix-blend-mode: overlay;
`
