import { useFrame } from "@react-three/fiber"
import React, { memo } from "react"
import { Raycaster, Vector2 } from "three"
import { useInputStore } from "../../stores/inputStore"
import { useMetaballStore } from "../../stores/metaballStore"

const MetaballCustomManipulator = memo(() => {
	// Properties
	const id = useMetaballStore((state) => state.selected.id)
	const mouseFromStore = useInputStore((state) => state.mouse)
	const scale = useMetaballStore((state) => state.metaball.objects[id]?.scale)
	const position = useMetaballStore(
		(state) => state.metaball.objects[id]?.position
	)

	// Actions
	const selectedActions = useMetaballStore((state) => state.selected.actions)
	const changePosition = selectedActions.changePosition

	useFrame(({ mouse, camera, scene }) => {
		//if (!mouseFromStore.pressed) return

		var raycaster = new Raycaster()
		const mouseVector = new Vector2(mouse.x, mouse.y)
		raycaster.setFromCamera(mouseVector, camera)

		const objIntersect = raycaster.intersectObjects(scene.children)

		for (var i = 0; i < objIntersect.length; i++) {
			const name = objIntersect[i].object.name
			if (name !== "metaball" && name !== id) return
		}

		const its = objIntersect[0]
		if (its) {
			changePosition(new Vector2(its.point.x, its.point.z))
		}
	})

	return <group />
})

MetaballCustomManipulator.displayName = "MetaballCustomManipulator"
export default MetaballCustomManipulator
