import { forwardRef, useState } from "react"
import { LoadingIndicator } from "../videoPlayer/videoPlayer_style"

interface HowToUseVideoProps {
	source: string
	background: string
	onTimeUpdate?: () => void
}

const HowToUseVideo = forwardRef<HTMLVideoElement, HowToUseVideoProps>(
	({ source, background, onTimeUpdate }, ref) => {
		const [isLoading, setIsLoading] = useState(false)

		return (
			<div
				style={{
					background,
				}}
				className="p-2 md:p-3 rounded-xl max-w-4xl relative"
			>
				<video
					muted
					autoPlay
					loop
					playsInline
					className="rounded-xl border-2 border-white shadow-md"
					ref={ref}
					preload="auto"
					onTimeUpdate={() => {
						if (onTimeUpdate) {
							onTimeUpdate()
						}
					}}
					onLoadStart={() => setIsLoading(true)}
					onLoadedMetadata={() => setIsLoading(false)}
					onLoadedData={() => setIsLoading(false)}
					poster={`/assets/images/${source}.jpg`}
				>
					<source src={`/assets/videos/${source}.mp4`} type="video/mp4" />
				</video>

				{isLoading && (
					<div className="absolute bottom-7 right-7 w-10 h-10 bg-white bg-opacity-20 backdrop-filter backdrop-blur-sm rounded-full flex items-center justify-center">
						<LoadingIndicator
							alt="Loading"
							src="/assets/images/loading.svg"
							width={22}
							height={22}
						/>
					</div>
				)}
			</div>
		)
	}
)

HowToUseVideo.displayName = "HowToUseVideo"
export default HowToUseVideo
