import { extend } from "@react-three/fiber"
import { ShaderMaterial, Vector2, Vector4 } from "three"

const vertexShaderText = /* glsl */ `
	varying vec2 vUv;
    void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }`

const fragmentShaderText = /* glsl */ `
	precision highp float;
	varying vec2 vUv;
	uniform float avatarOptions[COUNT]; 
	uniform vec4 avatarPositions[COUNT];

	vec4 flowyGradient(in vec2 uv) {
		vec4 flowyCol1 = vec4(0.4,0.102,1.0, 1.0);
		vec4 flowyCol2 = vec4(0.494,0.102,1.0,1.0);
		vec4 flowyCol3 = vec4(1.0,0.439,0.576, 1.0);
		vec4 flowyCol4 = vec4(1.0,0.671,0.584, 1.0);
 
		vec4 flowy;
		float angle = PI / 7.0; 
		float t =   (uv.x * cos(angle) - (1.0 - uv.y) * sin(angle)) + 0.2;

		if(t  < 0.1)  {
			flowy = mix(flowyCol1, flowyCol2, t/ 0.1 );
		} else if (t  < 0.7447) {
			flowy = mix(flowyCol2, flowyCol3, (t - 0.1)/ (0.7447 - 0.1));
		} else if (t < 0.8915) {
			flowy = mix(flowyCol3, flowyCol4, (t - 0.7447)/ (0.8915 - 0.7447));
		} else {
			flowy = flowyCol4;
		}
	
		return(flowy);
	}

    void main() {
		vec4 white = vec4(1.0, 1.0, 1.0, 1.0); 
		vec4 black = vec4(0.0, 0.0, 0.0, 1.0); 
		vec4 none = vec4(1.0, 1.0, 1.0, 0.0);
		vec4 flowy = flowyGradient(vUv);
		
		float v = 0.0;
		float scale = 0.0; 
		float signScale = 1.0;

		float dp = 0.0; 
		float dx = 0.0; 
		float dy = 0.0; 
		float sigmap = 300.0;
		float sigmat = 700.0;
		float meshScaleMax = max(float(SCALEX), float(SCALEY));
		float meshScaleXDivisor = 1.0;
		if(SCALEX < SCALEY)  {
			meshScaleXDivisor = float(SCALEX) / float(SCALEY);
		}

		float meshScaleYDivisor = 1.0;
		if(SCALEY < SCALEX)  {
			meshScaleYDivisor = float(SCALEY) / float(SCALEX);
		}



		#pragma unroll_loop_start
		for (int i = 0; i < COUNT; i++) {
			scale = abs(avatarOptions[i]) / (float(SCALEX) * float(SCALEY))  ; 
			signScale = sign(avatarOptions[i]);  

			dx = (avatarPositions[i].r *  meshScaleXDivisor - vUv.x *  meshScaleXDivisor);
			dy = (avatarPositions[i].g * meshScaleYDivisor - vUv.y * meshScaleYDivisor );
			v = v + signScale * scale * scale / (dx*dx + dy*dy);

			//dp = distance(avatarPositions[i].rg, vUv);
			//v = v  +  signScale * (((-1.0) / (0.5 + exp((-dp + scale) * sigmap))) + 2.0);
		}
		#pragma unroll_loop_end

		
		if (v > 1.0) {
			gl_FragColor = flowy;
		} else if (v > 0.99) {
			gl_FragColor = flowy; 
			gl_FragColor.a = mix(1.0, 0.0, (1.0 - v) / 0.01);
		} else if (v < -1.0) {
			gl_FragColor = none; 
			gl_FragColor.a = 0.0001;
		}else if (v < -0.96) {
			gl_FragColor = none; 
			//gl_FragColor.a = mix(0.6, 0.0, (1.0 - abs(v)) / 0.04);
		} else {
			gl_FragColor = none;
		}

		//gl_FragColor = flowy;
		// gl_FragColor.a = v; 
    }`

class MetaballMaterial extends ShaderMaterial {
	constructor(scale: [x: number, y: number], count: number) {
		super({
			defines: {
				SCALEX: scale[0] + 0.0,
				SCALEY: scale[1] + 0.0,
				COUNT: count === 0 ? 1 : count,
				PI: 3.14159265359,
			},
			uniforms: {
				avatarPositions: {
					value: new Array(1).fill(new Vector2(0, 0)),
				},
				avatarOptions: {
					value: new Array(1).fill(0),
				},
			},
			vertexShader: vertexShaderText,
			fragmentShader: fragmentShaderText,
			transparent: true,
		})
	}

	set avatarPositions(v: Array<Vector4>) {
		this.uniforms.avatarPositions.value = v
	}

	set avatarOptions(v: Array<number>) {
		this.uniforms.avatarOptions.value = v
	}
}

extend({ MetaballMaterial })
