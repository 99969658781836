import { FullGestureState } from "react-use-gesture/dist/types"
import { create } from "zustand"

type InputStore = {
	window: {
		focus: boolean
		inputFocus: boolean
	}

	mouse: {
		pos: number[]
		pressed: boolean
		pressedPos: number[]
		pressedTarget: undefined | HTMLElement
		isFree: boolean
	}

	keyboard: {
		keysDown: Set<string>
	}

	scroll: {
		top: number
		active: boolean
	}

	actions: {
		updateMouseDown: (down: FullGestureState<"drag">) => void
		updateMouseMove: (state: FullGestureState<"wheel">) => void
		updateWheel: (state: FullGestureState<"move">) => void
		updateKeydown: (event: KeyboardEvent) => void
		updateKeyup: (event: KeyboardEvent) => void
		updateInputFocus: (focus: boolean) => void
		updateFocus: (focus: boolean) => void
	}
}

export declare type Vector2 = [number, number]

export const useInputStore = create<InputStore>((set, get) => {
	return {
		window: {
			focus: true,
			inputFocus: false,
		},

		mouse: {
			pos: [0, 0],
			pressed: false,
			pressedPos: [0, 0],
			pressedTarget: undefined,
			isFree: false,
		},
		scroll: {
			top: 0,
			active: false,
		},

		keyboard: {
			keysDown: new Set(),
		},

		actions: {
			updateMouseDown(state: FullGestureState<"drag">) {
				var { mouse } = get()

				if (state.event) {
					const target = (state.event as PointerEvent).target

					if (target) {
						if (state.down && !mouse.pressed) {
							mouse.pressedTarget = target as HTMLElement
						}
						mouse.pressed = state.down
					}
				}
			},

			updateMouseMove(state: FullGestureState<"move">) {
				var { mouse } = get()

				mouse.pos[0] = state.xy[0]
				mouse.pos[1] = state.xy[1]
			},

			updateWheel(state: FullGestureState<"wheel">) {
				var { scroll } = get()
				// @ts-ignore
				const scrollValue = state.event.pageY
				// scroll.active = scrollValue !== scroll.top
				scroll.top = scrollValue
				set({ scroll })
			},

			updateKeydown(event: KeyboardEvent) {
				var { keyboard, window } = get()
				if (window.inputFocus) return
				keyboard.keysDown.add(event.key.toLowerCase())
			},

			updateKeyup(event: KeyboardEvent) {
				var { keyboard } = get()

				keyboard.keysDown.delete(event.key.toLowerCase())
			},

			updateInputFocus(focus: boolean) {
				var { window } = get()
				window.inputFocus = focus
			},

			updateFocus(focus: boolean) {
				var { window, keyboard, mouse } = get()
				window.focus = focus

				if (!focus) {
					keyboard.keysDown = new Set()
					mouse.pressed = false
				}
			},
		},
	}
})
