import { Mesh, Vector2 } from "three"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

export type MetaballObject = {
	mesh?: Mesh
	scale: number
	position: Vector2
}

type MetaballActions = {
	add: (id: string, metaballObject: MetaballObject) => void
	remove: (id: string) => void
	setMesh: (id: string, mesh: Mesh | undefined) => void
	changeScale: (id: string, scale: number) => void
	changePosition: (id: string, position: Vector2) => void
	hideMesh: (hide: boolean) => void
}

type Metaball = {
	isHidden: boolean
	objects: Record<string, MetaballObject>
	actions: MetaballActions
}

type SelectedMetaballActions = {
	set: (id: string) => void
	changeScale: (scale: number) => void
	increaseScale: (delta: number) => void
	changePosition: (position: Vector2) => void
}

type SelectedMetaball = {
	id: string
	actions: SelectedMetaballActions
}

type MetaballStore = {
	metaball: Metaball
	selected: SelectedMetaball
}

export const useMetaballStore = create(
	immer<MetaballStore>((set, _get) => ({
		metaball: {
			isHidden: true,
			objects: {
				default: {
					position: new Vector2(0.0, 0),
					scale: -15.0,
				},
				ball1: {
					position: new Vector2(7.0, -6.0),
					scale: 50.0,
				},
				ball2: {
					position: new Vector2(5.0, 2.5),
					scale: 20.0,
				},
				ball3: {
					position: new Vector2(-5.5, 2.0),
					scale: 30.0,
				},
				ball4: {
					position: new Vector2(-6.5, -4.5),
					scale: 15.0,
				},
				ball5: {
					position: new Vector2(13.0, -0.5),
					scale: 15.0,
				},
				ball6: {
					position: new Vector2(-12.0, -0.5),
					scale: 22.0,
				},
			},
			actions: {
				hideMesh: (hide): void => {
					set((state) => {
						state.metaball.isHidden = hide
					})
				},
				remove: (id): void => {
					if (id !== "default") {
						set((state) => {
							state.selected.id = "default"
							delete state.metaball.objects[id]
						})
					}
				},

				add: (id, metaballObject): void => {
					set((state) => {
						state.selected.id = id
						state.metaball.objects[id] = metaballObject
					})
				},
				setMesh: (id, mesh): void => {
					set((state) => {
						if (state.metaball.objects[id]) {
							state.metaball.objects[id].mesh = mesh
						}
					})
				},
				changeScale: (id, scale): void => {
					set((state) => {
						if (state.metaball.objects[id]) {
							state.metaball.objects[id].scale = scale
						}
					})
				},
				changePosition: (id, position): void => {
					set((state) => {
						if (state.metaball.objects[id]) {
							state.metaball.objects[id].position = position
						}
					})
				},
			},
		},

		selected: {
			id: "default",
			actions: {
				set: (id): void => {
					set((state) => {
						state.selected.id = id
					})
				},
				changeScale: (scale): void => {
					set((state) => {
						state.metaball.objects[state.selected.id].scale = scale
					})
				},
				increaseScale: (delta): void => {
					set((state) => {
						state.metaball.objects[state.selected.id].scale += delta
					})
				},
				changePosition: (position): void => {
					set((state) => {
						state.metaball.objects[state.selected.id].position = position
					})
				},
			},
		},
	}))
)
