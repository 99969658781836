import { Canvas } from "@react-three/fiber"
import { MathUtils, NoToneMapping, sRGBEncoding } from "three"
import { shallow } from "zustand/shallow"
import { useMetaballStore } from "../../stores/metaballStore"
import Camera from "../metaball/camera"
import MetaballPlane from "../metaball/metaballPlane"
import MetaballCustomManipulator from "../metaballManipulator/metaballCustomManipulator"
import MetaballObject from "../metaballObject/metaballObject"
import { Container } from "./metaballBackground_style"

const MetaballBackground = () => {
	// Reloading Check
	console.log("Metball Background Reload")

	// Stores
	const objects = useMetaballStore(
		(state) => Object.keys(state.metaball.objects),
		shallow
	)

	// Properties
	const bounds: [width: number, height: number] = [30, 14]
	const rotation: [x: number, y: number, z: number] = [
		MathUtils.degToRad(-90),
		MathUtils.degToRad(0),
		MathUtils.degToRad(0),
	]

	return (
		<Container>
			<Canvas
				linear
				orthographic
				dpr={1}
				onCreated={({ gl }) => {
					gl.toneMapping = NoToneMapping
					gl.outputEncoding = sRGBEncoding
				}}
			>
				<MetaballPlane bounds={bounds} rotation={rotation} />

				{objects.map((id) => {
					return <MetaballObject id={id} key={id} />
				})}

				<MetaballCustomManipulator />
				<Camera />
			</Canvas>
		</Container>
	)
}

export default MetaballBackground
