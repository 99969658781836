import { useThree } from "@react-three/fiber"
import React, { useEffect } from "react"

const Camera = () => {
	// Reloading Check
	// console.log("Camera Reload")

	// Properties
	const { scene, camera } = useThree()

	useEffect(() => {
		window.innerWidth
		camera.position.set(0, 10, 0)
		camera.lookAt(scene.position)
		camera.zoom = 100

		camera.updateProjectionMatrix()
	}, [camera, scene.position])

	return <mesh />
}

export default Camera
