import { H1, H2, H4, HorizontalGroup, P, VerticalGroup } from "@saysom/shared"
import React from "react"
import { Container, SmallContainer } from "../../../styles/index"
import DirectionSwitch from "../../directionSwitch"

const WhatYouGet = () => {
	return (
		<Container style={{ background: "rgba(51, 51, 51, 0.1)" }}>
			<H1 color="white" style={{ marginBottom: "10px" }}>
				What you get
			</H1>
			<H4 color="rgba(255,255,255,0.8)" style={{ marginBottom: "20px" }}>
				We listen to our customers and design and refine SaySom after their
				needs.
			</H4>

			<DirectionSwitch>
				<SmallContainer
					border="2px solid rgba(255, 255, 255, 0.4)"
					style={{ flex: 1 }}
				>
					<HorizontalGroup
						style={{ marginBottom: "10px" }}
						alignItems="center"
						justifyContent="space-between"
					>
						<H2 color="white">Analytics</H2>
						<div
							style={{
								padding: "6px 4px",
								border: "1px solid rgba(255,255,255,0.7)",
								borderRadius: "4px",
							}}
						>
							<P color="white">Coming Soon</P>
						</div>
					</HorizontalGroup>
					<H4
						style={{ lineHeight: "24px", textAlign: "justify" }}
						color="rgba(255,255,255,0.7)"
					>
						Learn from your participants activity. We create a fully anonymus
						heatmap for you.
					</H4>
					<img
						src="/assets/images/analytics@2x.png"
						srcSet="/assets/images/analytics@2x.png 2x,/assets/images/analytics.png 1x"
						style={{ marginTop: "20px" }}
						width="100%"
						alt="Analytics for your space"
					/>
				</SmallContainer>
				<VerticalGroup flex="1" spacing={30}>
					<SmallContainer backgroundColor="rgba(255, 255, 255, 0.4)">
						<H2 color="white" style={{ marginBottom: "10px" }}>
							Participant Satisfaction
						</H2>
						<H4
							style={{ lineHeight: "24px", textAlign: "justify" }}
							color="rgba(255,255,255,0.7)"
						>
							We are proud to say that participants and hosts love SaySom with a
							rating of 4.8 / 5.0. Need help setting up or designing your space?
							We have your back! Even during your event.
						</H4>
						<img
							src="/assets/images/stars.png"
							srcSet="/assets/images/stars.png 1x, /assets/images/stars@2x.png 2x"
							style={{
								marginTop: "20px",
								alignSelf: "center",
								opacity: "0.7",
							}}
							alt="4.8 Stars"
							width="70%"
						/>
					</SmallContainer>
					<SmallContainer backgroundColor="rgba(255, 255, 255, 0.4)">
						<H2 color="white" style={{ marginBottom: "10px" }}>
							Support
						</H2>
						<H4
							style={{ lineHeight: "24px", textAlign: "justify" }}
							color="rgba(255,255,255,0.7)"
						>
							Not sure if your participants need assistance using SaySom? We are
							here to help. Qualified staff will support you in preparation and
							during the event.
						</H4>
						<VerticalGroup
							justifyContent={"space-between"}
							alignItems={"center"}
							flex={1}
						>
							<div />
							<div
								style={{
									marginTop: "20px",
									padding: "6px 4px",
									border: "2px solid rgba(255,255,255,0.7)",
									borderRadius: "8px",
									opacity: 0.7,
								}}
							>
								<H1
									color="rgba(255,255,255,0.8)"
									style={{ fontWeight: 300, fontSize: "40px" }}
								>
									24 / 7
								</H1>
							</div>
							<div />
						</VerticalGroup>
					</SmallContainer>
				</VerticalGroup>
			</DirectionSwitch>
		</Container>
	)
}

export default WhatYouGet
