import { H2, VerticalGroup } from "@saysom/shared"
import { useRouter } from "next/router"
import React from "react"
import { SmallContainer } from "../../styles"
import ContinueButton from "../buttons/continueButton"
import DirectionSwitch from "../directionSwitch"

const LearnMore = () => {
	const router = useRouter()
	return (
		<DirectionSwitch className="mb-20">
			<SmallContainer
				shadow="0px 0px 15px rgba(0, 0, 0, 0.16)"
				justify="space-between"
				className="space-y-8"
			>
				<VerticalGroup className="space-y-2">
					<H2 className="bg-flowy-text bg-clip-text text-transparent self-center">
						Pricing
					</H2>
					<p>
						SaySom is free for 60 minutes and 20 participants, you need more
						time and space?
					</p>
				</VerticalGroup>

				<ContinueButton
					href="/pricing"
					text="Pricing"
					className="self-center"
					isBlack
				/>
			</SmallContainer>
			<SmallContainer
				justify="space-between"
				gradient
				shadow="0px 0px 15px rgba(0, 0, 0, 0.16)"
				className="space-y-8 text-white"
			>
				<VerticalGroup alignItems="center" className="space-y-2">
					<H2>Experience SaySom</H2>
					<p>
						Stop wondering about what your dream network event would look like
					</p>
				</VerticalGroup>

				<ContinueButton
					href="/space/create"
					text="Create space"
					className="self-center"
				/>
			</SmallContainer>
			<SmallContainer
				shadow="0px 0px 15px rgba(0, 0, 0, 0.16)"
				justify="space-between"
				className="space-y-8"
			>
				<VerticalGroup className="space-y-2">
					<H2 className="bg-flowy-text bg-clip-text text-transparent self-center">
						About Us
					</H2>
					<p>
						We are a young technology startup want to know more about who we
						are?
					</p>
				</VerticalGroup>

				<ContinueButton
					href="/about"
					text="About us"
					className="self-center"
					isBlack
				/>
			</SmallContainer>
		</DirectionSwitch>
	)
}

export default LearnMore
