import { Colors } from "@saysom/shared"
import { animated } from "react-spring"
import styled from "styled-components"

export const Container = styled.div`
	pointer-events: none;
	align-self: center;

	@media (min-width: 1000px) and (max-height: 1000px) {
		width: calc(100vh - 200px * 0.6);
	}

	@media (min-width: 1000px) and (max-height: 800px) {
		width: calc(100vh - 300px * 0.6);
	}
`

export const CustomImage = styled(animated.img)`
	width: 100%;
	height: 100%;
	display: block;
	border-radius: 10px;

	@media (max-width: 500px) {
		border-radius: 5px;
	}
`

export const ImageContainer = styled(animated.div)`
	position: relative;
	max-width: 1024px;
	width: 100%;
	border-radius: 12px;
	overflow: hidden;

	background: ${Colors.flowy};

	/* backdrop-filter: blur(100px); */
	box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);

	@media (max-width: 1024px) {
		width: 100%;
	}
`
