import { H1, H2, H3, H4, VerticalGroup } from "@saysom/shared"
import React from "react"
import { Container } from "../../styles/index"
import DirectionSwitch from "../directionSwitch"
import CantileverGroup from "./cantileverGroup"
import HowToUseVideo from "./howToUseVideo"

const HowToUse = () => {
	return (
		<VerticalGroup spacing={20}>
			<Container
				style={{
					background:
						"linear-gradient(107.61deg, rgba(255, 255, 255, 0.2) 1.77%, rgba(255, 255, 255, 0) 100%)",
				}}
				className="text-white space-y-16"
			>
				<div>
					<H1 className="mb-2">How to use</H1>
					<H3 className="mix-blend-overlay">
						A unmatched form of flexibility for networking events
					</H3>
				</div>

				<DirectionSwitch>
					<CantileverGroup>
						<HowToUseVideo
							source="networking"
							background="linear-gradient(107.54deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(111.16deg, rgba(255, 151, 123, 0.2) 0%, rgba(255, 151, 123, 0.8) 100%), #FFFFFF"
						/>
					</CantileverGroup>

					<VerticalGroup flex={1} className="space-y-2.5 py-2.5">
						<H3>SaySom for Networking</H3>
						<H4
							className="text-white text-opacity-60"
							style={{ textAlign: "justify" }}
						>
							Sometimes it&apos;s hard to network digitally. We know that. At
							SaySom, we keep the barriers to reaching out to someone new as low
							as possible. Did you know that videos of other participants are
							permanently displayed?
							<br />
							<br />
							Design your space the way you want it. With a speed dating area,
							for example, you incentivize your participants to get to know
							others.
						</H4>
					</VerticalGroup>
				</DirectionSwitch>

				<DirectionSwitch onSwitchFlexReverse>
					<VerticalGroup flex={1} className="space-y-2.5 py-2.5 mt-8 md:mt-0">
						<H3>SaySom for Fun</H3>
						<H4
							className="text-white text-opacity-60"
							style={{ textAlign: "justify" }}
						>
							Whether after-work, birthday celebration or summer party.
							Sometimes you just want a little fun at a digital event. Set up
							individuall games for your participant to light up the mood.
							<br />
							<br />
							You want to give your event a special kick? On request we
							integrate livestreams, videos or other formats.
						</H4>
					</VerticalGroup>

					<CantileverGroup cantileverRight>
						<HowToUseVideo
							source="fun"
							background="linear-gradient(107.54deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(111.09deg, rgba(126, 227, 154, 0.1) 0%, rgba(126, 227, 154, 0.8) 100%), #FFFFFF"
						/>
					</CantileverGroup>
				</DirectionSwitch>

				<DirectionSwitch>
					<CantileverGroup>
						<HowToUseVideo
							source="workshop"
							background="linear-gradient(107.54deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(111.09deg, rgba(243, 120, 160, 0.16) 0%, rgba(248, 84, 137, 0.8) 100%), #FFFFFF"
						/>
					</CantileverGroup>

					<VerticalGroup flex={1} className="space-y-2.5 py-2.5">
						<H3>SaySom for Workshops</H3>
						<H4
							className="text-white text-opacity-60"
							style={{ textAlign: "justify" }}
						>
							Do you want your participants to interact with each other in
							specific groups? Design your space according to your individual
							logic and show your participants ways they should go. How you
							design the space is your decision!
						</H4>
					</VerticalGroup>
				</DirectionSwitch>

				<DirectionSwitch onSwitchFlexReverse spaceY={0}>
					<VerticalGroup flex={1} className="space-y-2.5 py-2.5 mt-8 md:mt-0">
						<H3>SaySom for Presentation</H3>
						<H4
							className="text-white text-opacity-60"
							style={{ textAlign: "justify" }}
						>
							Your event has different agenda items? No problem at all. Give
							moderation rights and allow speakers to address the entire room.
							<br />
							<br />
							You have a panel discussion? Just give moderation rights to
							several speakers and they can address the whole room at the same
							time and disscuss.
						</H4>
					</VerticalGroup>

					<CantileverGroup cantileverRight>
						<HowToUseVideo
							source="presentation"
							background="linear-gradient(107.54deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(111.09deg, rgba(102, 26, 255, 0.1) 0%, rgba(126, 26, 255, 0.8) 100%), #FFFFFF"
						/>
					</CantileverGroup>
				</DirectionSwitch>
			</Container>

			<DirectionSwitch>
				<Container
					style={{
						flex: 1,
						background: "rgba(255, 255, 255, 0.2)",
					}}
					spacing={30}
					className="text-white"
				>
					<H2>Customizable</H2>

					<H4
						style={{ lineHeight: "24px", opacity: 0.7, textAlign: "justify" }}
					>
						Every event is individual. That&apos;s why we put great emphasis on
						customizability. Create your individual space as simple as drag and
						drop. Simply drag areas, images, shapes or paths into your space and
						adapt it according to your personal needs.
					</H4>

					<img
						src="/assets/images/customizable-demo.png"
						srcSet="/assets/images/customizable-demo.png 1x, /assets/images/customizable-demo@2x.png 2x"
						alt="Use built-in tools to customize your space to your needs"
					/>
				</Container>
				<Container
					style={{ flex: 1, color: "white" }}
					spacing={30}
					className="bg-flowy"
				>
					<H2>Subspaces</H2>

					<H4
						style={{ lineHeight: "24px", opacity: 0.7, textAlign: "justify" }}
					>
						For very large and diverse audience a space can be split into
						multiple sub spaces. This makes group control easier and additonally
						allows participants to jump between multiple presentation sessions.
					</H4>

					<img
						src="/assets/images/subspaces-demo.png"
						srcSet="/assets/images/subspaces-demo.png 1x, /assets/images/subspaces-demo@2x.png 2x"
						alt="Split a space into multiple subspaces to gain more flexibility"
					/>
				</Container>
			</DirectionSwitch>
		</VerticalGroup>
	)
}

export default HowToUse
