import { Colors, H1, VerticalGroup } from "@saysom/shared"
import { useRouter } from "next/router"
import React, { useRef } from "react"
import { use100vh } from "react-div-100vh"
import { useDrag, useMove, useWheel } from "react-use-gesture"
import { Container, InnerContainer } from "../components/body/body_style"
import Footer from "../components/body/footer"
import Header from "../components/body/header"
import ContinueButton from "../components/buttons/continueButton"
import FeatureBanner from "../components/landing/featureBanner/featureBanner"
import HowItWorks from "../components/landing/howItWorks"
import HowToUse from "../components/landing/howToUse"
import LearnMore from "../components/landing/learnMore"
import Recommendations from "../components/landing/recommendations/recommendations"
import { DottedBackground } from "../components/landing/recommendations/recommendations_style"
import WhatYouGet from "../components/landing/whatYouGet/whatYouGet"
import MetaballBackground from "../components/metaballBackground/metaballBackground"
import SpaceCard from "../components/spaceCard/spaceCard"
import { useContactStore } from "../stores/contactStore"
import { useInputStore } from "../stores/inputStore"
import { FirstSection, GradientSection, Section } from "../styles/landing_style"

const Landing = () => {
	const height = use100vh() ?? 0 + 60

	const actions = useContactStore((state) => state)

	const inputActions = useInputStore((state) => state.actions)
	const drag = useDrag((state) => inputActions.updateMouseDown(state))
	const wheel = useWheel((state) => inputActions.updateWheel(state))
	const move = useMove((state) => inputActions.updateMouseMove(state))
	const router = useRouter()

	// Ref
	const video = useRef<HTMLElement>(null)
	const rooms = useRef<HTMLElement>(null)
	const contactTopButton = useRef<HTMLButtonElement>(null)

	return (
		<Container
			style={{ width: "100vw" }}
			{...wheel()}
			{...move()}
			{...drag()}
			className="bg-white dark:bg-black text-black dark:text-white"
		>
			<MetaballBackground />

			<Header />

			<FirstSection height={height}>
				<div style={{ flex: 1 }} />

				<VerticalGroup spacing={20}>
					<H1
						style={{
							flex: "0",
							backgroundImage: Colors.flowyText,
							WebkitBackgroundClip: "text",
							WebkitTextFillColor: "transparent",
							whiteSpace: "nowrap",
							alignSelf: "center",
							textAlign: "center",
						}}
					>
						Digital Networking Spaces
					</H1>
					<h2
						style={{
							fontWeight: 400,
							alignSelf: "center",
							textAlign: "center",
							fontSize: "16px",
							lineHeight: "20px",
						}}
					>
						Turn your networking event into something special and give your
						<br />
						participants a memorable experience no matter where they are.
					</h2>
				</VerticalGroup>

				<div style={{ flex: 0.4 }} />

				<SpaceCard />

				<div style={{ flex: 0.3 }} />

				<ContinueButton
					href="/space/PcjqZ0mW9a"
					text="Try the demo"
					className="self-center pointer-events-auto mt-2"
					isBlack
				/>

				<div style={{ flex: 1.4 }} className="flex items-end justify-center">
					<svg
						width="30"
						height="30"
						viewBox="0 0 30 30"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className="animate-bounce"
					>
						<path
							d="M2 2L15 12L28 2"
							stroke="currentColor"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
			</FirstSection>

			<GradientSection>
				<InnerContainer>
					<HowItWorks />
				</InnerContainer>
			</GradientSection>

			<GradientSection
				style={{
					background:
						"linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), linear-gradient(58.22deg, #521AFF 0%, #7E1AFF 16.31%, #DF738E 79.5%, #F9BAA9 97.85%)",
				}}
			>
				<InnerContainer>
					<HowToUse />
				</InnerContainer>
			</GradientSection>

			<GradientSection fadesDown={true} style={{ position: "relative" }}>
				<InnerContainer>
					<WhatYouGet />
				</InnerContainer>
				<VerticalGroup
					style={{
						position: "relative",
						padding: "160px 0px",
						margin: "120px 0px",
					}}
				>
					<InnerContainer>
						<Recommendations />
					</InnerContainer>
					<DottedBackground style={{ left: "-23px" }} />
				</VerticalGroup>

				<div className="absolute left-0 right-0 bottom-0 h-2 bg-white dark:bg-black" />
			</GradientSection>
			<FeatureBanner />

			<Section style={{ paddingBottom: 0, marginTop: "120px" }}>
				<InnerContainer>
					<LearnMore />
				</InnerContainer>
			</Section>

			<Footer />
		</Container>
	)
}

export default Landing
