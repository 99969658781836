import { useFrame } from "@react-three/fiber"
import { FunctionComponent, MutableRefObject, useEffect, useRef } from "react"
import {
	BufferGeometry,
	Material,
	Mesh,
	Raycaster,
	Vector2,
	Vector3,
} from "three"
import { MetaballObject, useMetaballStore } from "../../stores/metaballStore"
import "./metaballMaterial"

interface MetaballProps {
	layerMask?: number
	bounds: [width: number, height: number]
	rotation: [x: number, y: number, z: number]
}

const MetaballPlane: FunctionComponent<MetaballProps> = ({
	bounds,
	rotation,
	layerMask = 1,
}) => {
	// Properties
	const count = useMetaballStore(
		(state) => Object.keys(state.metaball.objects).length
	)
	console.log(count)

	const objects: MutableRefObject<Record<string, MetaballObject>> = useRef({})
	useEffect(() => {
		useMetaballStore.subscribe(
			(state) => (objects.current = state.metaball.objects)
		)
	}, [])

	// Refs
	const mesh = useRef<Mesh>(null)
	const material = useRef<Material>()
	const geometry = useRef<BufferGeometry>()

	const searchFloorIntersection = (pos: THREE.Vector2) => {
		if (!mesh || !mesh.current) return

		// Lift raystart over ground
		const position = new Vector3(pos.x, 0.01, pos.y)

		// Raycast
		var raycaster = new Raycaster()
		raycaster.set(position, new Vector3(0, -1, 0))
		raycaster.layers.set(layerMask)
		mesh.current.layers.enable(layerMask)

		// Intersection with background#
		var intersects = raycaster.intersectObject(mesh.current)
		mesh.current.layers.enable(0)

		// First intersection
		return intersects[0]
	}

	const updateMetaballMaterial = () => {
		if (!mesh?.current) return
		if (!objects?.current) return
		if (!material?.current) return
		if (!geometry?.current) return

		// Material position array
		var matAvatarPos = new Array(count).fill(new Vector2(10, 10))
		var matAvatarOpt = new Array(count).fill(0)

		const objectArray = Object.values(objects.current)
		for (var i = 0; i < count; i++) {
			const object = objectArray[i]

			//console.log(object?.mesh?.position)

			if (!object?.mesh?.position) continue

			const position = new Vector2(
				object.mesh?.position.x,
				object.mesh?.position.z
			)

			var int = searchFloorIntersection(position)

			if (int?.uv) {
				matAvatarOpt[i] = object.scale
				matAvatarPos[i] = new Vector2(int.uv.x, int.uv.y)
			}
		}

		// @ts-ignore
		material.current.avatarOptions = matAvatarOpt
		// @ts-ignore
		material.current.avatarPositions = matAvatarPos
	}

	useFrame(() => {
		updateMetaballMaterial()
	})

	return (
		// @ts-ignore
		<mesh name="metaball" ref={mesh} rotation={rotation}>
			<planeBufferGeometry ref={geometry} attach="geometry" args={bounds} />
			{
				// @ts-ignore
				<metaballMaterial
					ref={material}
					attach="material"
					args={[bounds, count]}
				/>
			}
		</mesh>
	)
}

export default MetaballPlane
