import {
	H1,
	H2,
	H3,
	H4,
	HorizontalGroup,
	P,
	VerticalGroup,
} from "@saysom/shared"
import React from "react"
import { Container } from "../../styles"
import DirectionSwitch from "../directionSwitch"
import HowItWorksVideo from "./howItWorksVideo"

const HowItWorks = () => {
	return (
		<VerticalGroup spacing={20}>
			<Container className="bg-white dark:bg-black">
				<H1>How it works</H1>
				<H3 className="bg-flowy-text bg-clip-text text-transparent self-start mt-2 mb-5">
					A new form of lifelike communication
				</H3>

				<HowItWorksVideo />
			</Container>

			<DirectionSwitch>
				<Container
					style={{ flex: 1 }}
					spacing={30}
					className="bg-white dark:bg-black"
				>
					<H2>Megaphone</H2>

					<H4
						style={{ lineHeight: "24px", opacity: 0.7, textAlign: "justify" }}
					>
						A live video notification that everyone in the space is able to
						hear. Moderation as easy as it colud be. Moderators can be assigned
						throughout the event, to give the opportunity to speak to the entire
						space.
					</H4>

					<img
						src="/assets/images/megaphone-demo.png"
						srcSet="/assets/images/megaphone-demo.png 1x, /assets/images/megaphone-demo@2x.png 2x"
						className="rounded-xl"
						alt="Use the megaphone to reach everybody"
					/>
				</Container>
				<Container
					style={{ flex: 1, color: "white" }}
					spacing={30}
					className="bg-flowy"
				>
					<HorizontalGroup alignItems="center" justifyContent="space-between">
						<H2>Spatial Chat</H2>
						<div
							style={{
								padding: "6px 4px",
								border: "1px solid rgba(255,255,255,0.7)",
								borderRadius: "4px",
							}}
						>
							<P>Coming Soon</P>
						</div>
					</HorizontalGroup>

					<H4
						style={{ lineHeight: "24px", opacity: 0.7, textAlign: "justify" }}
					>
						Want to share a link or talk to others while your microphone is off?
						No problem, just chat locally. Talk in private with one other person
						or send notifactions into a bubble or to the whole space.
					</H4>

					<img
						src="/assets/images/spatialchat-demo.png"
						srcSet="/assets/images/spatialchat-demo.png 1x, /assets/images/spatialchat-demo@2x.png 2x"
						alt="Talk in private with one other person or send notifactions into a bubble or to the whole space."
					/>
				</Container>
			</DirectionSwitch>
		</VerticalGroup>
	)
}

export default HowItWorks
