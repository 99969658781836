import { PropsWithChildren } from "react"

const CantileverGroup = ({
	cantileverRight = false,
	children,
	className,
}: PropsWithChildren<{
	cantileverRight?: boolean
	className?: string
}>) => {
	return (
		<div
			style={{
				flex: 1,
				height: "calc(min(45vw, 48rem) * 0.5625)",
			}}
			className={`relative ${className}`}
		>
			<div
				className={`static lg:absolute ${
					cantileverRight ? "left-4" : "right-4"
				} lg:w-45vw max-w-3xl`}
			>
				<div className="aspect-w-16 aspect-h-9">
					<div className="inset-0">{children}</div>
				</div>
			</div>
		</div>
	)
}

export default CantileverGroup
