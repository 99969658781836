import styled from "styled-components"

export const Container = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	//z-index: -100;

	@media (max-width: 750px) {
		display: none;
	}
`
export const Gradient = styled.div`
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	height: 20%;

	background: linear-gradient(
		to top,
		rgba(255, 255, 255, 1),
		rgba(255, 255, 255, 0)
	);
`
