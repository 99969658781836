import { useFrame } from "@react-three/fiber"
import { memo, MutableRefObject, useCallback, useEffect, useRef } from "react"
import { Mesh } from "three"
import {
	MetaballObject as MB,
	useMetaballStore,
} from "../../stores/metaballStore"

interface MetaballObjectProps {
	id: string
}

const MetaballObject = memo<MetaballObjectProps>(({ id }) => {
	const setSelected = useMetaballStore((state) => state.selected.actions.set)
	const setMesh = useMetaballStore((state) => state.metaball.actions.setMesh)
	const selectedId = useMetaballStore((state) => state.selected.id)
	const isHidden = useMetaballStore((state) => state.metaball.isHidden)

	// const keysDown = useInputStore((state) => state.keyboard.keysDown)

	const object: MutableRefObject<MB | undefined> = useRef(undefined)
	useEffect(() => {
		useMetaballStore.subscribe(
			(state) => (object.current = state.metaball.objects[id])
		)
	}, [id])

	useFrame(() => {
		if (!object?.current?.mesh) return

		const mesh = object.current.mesh
		const pos = object.current.position
		mesh.position.set(pos.x, 0, pos.y)
	})

	const setRef = useCallback(
		(newRef: Mesh | null) => {
			if (newRef === null) {
				setMesh(id, undefined)
			} else {
				setMesh(id, newRef)
			}
		},
		[setMesh, id]
	)

	return (
		<mesh
			name={id}
			// @ts-ignore
			ref={setRef}
			onClick={() => setSelected(id)}
			scale={[0.1, 0.1, 0.1]}
			position={[100, 100, 100]}
			visible={!isHidden}
		>
			<sphereBufferGeometry args={[1, 100]} attach="geometry" />

			<meshBasicMaterial
				attach="material"
				color={id === selectedId ? "purple" : "gray"}
			/>
		</mesh>
	)
})

MetaballObject.displayName = "MetaballObject"
export default MetaballObject
