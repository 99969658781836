import { HorizontalGroup, Shadows } from "@saysom/shared"
import Image from "next/image"
import styled from "styled-components"
import { Button } from "../../styles"

export const Container = styled.div``

export const VideoContainer = styled.div`
	position: relative;
	display: flex;
`

export const Video = styled.video`
	width: 100%;
	height: 100%;
	border-radius: 20px;
	box-shadow: ${Shadows.highlighted};

	@media (max-width: 500px) {
		border-radius: 10px;
	}
`

export const PlayButton = styled(Button)`
	position: absolute;
	bottom: 18px;
	right: 18px;
	width: 40px;
	height: 40px;
	background: rgba(255, 255, 255, 0.2);
	backdrop-filter: blur(4px);
	border-radius: 20px;
	padding: 0;
	box-shadow: none;

	display: flex;
	align-items: center;
	justify-content: center;
`

export const ProgressContainer = styled(HorizontalGroup)`
	padding: 0 20px;
	margin-top: 30px;

	@media (max-width: 500px) {
		padding: 0;
	}
`

export const Spacer = styled.div`
	width: 40px;

	@media (max-width: 500px) {
		width: 10px;
	}
`

export const LoadingIndicator = styled(Image)`
	animation: rotation 1.5s steps(12) infinite;

	@keyframes rotation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`
