import { PropsWithChildren } from "react"

const DirectionSwitch = ({
	children,
	spaceY = 8,
	onSwitch = "md",
	onSwitchFlexReverse = false,
	className,
	...props
}: PropsWithChildren<{
	spaceY?: number
	onSwitch?: string
	onSwitchFlexReverse?: boolean
	className?: string
}>) => {
	return (
		<div
			className={`flex ${
				onSwitchFlexReverse ? "flex-col-reverse" : "flex-col"
			} space-y-${spaceY} items-stretch ${onSwitch}:flex-row
			 ${onSwitch}:space-x-5 ${onSwitch}:space-y-0 ${className}`}
			{...props}
		>
			{children}
		</div>
	)
}

export default DirectionSwitch
