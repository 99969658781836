import { Shadows } from "@saysom/shared"
import Image from "next/image"
import { animated } from "react-spring"
import styled from "styled-components"
import { Gradient } from "."
import { InnerContainer } from "../components/body/body_style"
import Footer from "../components/body/footer"

export const Section = styled.section`
	display: flex;
	flex-direction: column;

	padding: 140px 0 150px 0;

	@media (max-width: 500px) {
		padding: 80px 0 90px 0;
	}
`

// export const GradientSection = styled(Section)`
// 	background: linear-gradient(54.6deg, #fef3ff 0%, #d5d2ff 100%);
// `

export const GradientSection = styled(Section)<{ fadesDown?: boolean }>`
	background: ${(props) =>
		props.fadesDown
			? "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 90%, rgba(255, 255, 255, 1) 100%), linear-gradient(141.48deg, #521AFF 0%, #7E1AFF 16.85%, #DF738E 82.17%, #F9BAA9 101.13%)"
			: "linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.8) 47.4%, rgba(255, 255, 255, 0) 100%), linear-gradient(58.22deg, #521AFF 0%, #7E1AFF 16.31%, #DF738E 79.5%, #F9BAA9 97.85%)"};

	@media (prefers-color-scheme: dark) {
		background: ${(props) =>
			props.fadesDown
				? "linear-gradient(180deg, rgba(51, 51, 51, 0) 0%, rgba(51, 51, 51, 0.8) 90%, rgba(51, 51, 51, 1) 100%), linear-gradient(141.48deg, #521AFF 0%, #7E1AFF 16.85%, #DF738E 82.17%, #F9BAA9 101.13%)"
				: "linear-gradient(180deg, #333 0%, rgba(51, 51, 51, 0.8) 47.4%, rgba(51, 51, 51, 0) 100%), linear-gradient(58.22deg, #521AFF 0%, #7E1AFF 16.31%, #DF738E 79.5%, #F9BAA9 97.85%)"};
	}
`

export const FullWidth = styled.div`
	max-width: 100%;
`

export const CustomImage = styled(animated.img).attrs({
	draggable: false,
})`
	width: 100%;
`

export const FirstSection = styled(Section)<{ height: number }>`
	display: flex;
	background: none;
	//cursor: none;
	pointer-events: none;
	position: relative;
	min-height: calc(100vh - 64px);
	padding: 20px 20px 0 20px;

	justify-content: space-around;
	align-content: center;

	@media (max-width: 500px) {
		min-height: ${(props) => props.height - 64}px;
	}
`

export const FirstInnerContainer = styled(InnerContainer)`
	//justify-content: space-around;
`

export const LearnMoreImage = styled(Image)`
	align-self: center;
	margin-top: 10px;

	animation: move 1.5s infinite alternate;

	@keyframes move {
		0% {
			transform: translate(0, 0px);
		}
		100% {
			transform: translate(0, 8px);
		}
	}
`

export const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 40px;

	@media (max-width: 500px) {
		grid-template-columns: 1fr;
		grid-gap: 20px;
	}
`

export const Advantage = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const AdvantageImage = styled(Image)`
	margin-bottom: 60px;

	@media (max-width: 500px) {
		height: 120px;
		width: 120px;
		margin-bottom: 30px;
	}
`

export const Usage = styled.div`
	background: #ffffff;
	box-shadow: ${Shadows.highlighted};
	border-radius: 20px;
	padding: 20px;
	text-align: justify;

	@media (max-width: 500px) {
		grid-column-start: initial !important;
		grid-column-end: initial !important;
		border-radius: 10px;
	}
`

export const B = styled.b`
	font-weight: 800;
	background: linear-gradient(45deg, #fa8bff 0%, #6867ff 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
`

export const StyledFooter = styled(Footer)`
	margin: 130px 0 50px 0 !important;

	@media (max-width: 500px) {
		margin: 130px 0 30px 0 !important;
	}
`

export const Working = styled.div`
	position: relative;
`

export const Desktop = styled.div`
	display: block;

	@media (max-width: 500px) {
		display: none;
	}
`

export const Mobile = styled(Desktop)`
	display: none;

	@media (max-width: 500px) {
		display: block;
	}
`

export const WorkingImage = styled(CustomImage)`
	border-radius: 20px;
	box-shadow: ${Shadows.highlighted};

	@media (max-width: 500px) {
		border-radius: 10px;
	}
`

export const WorkingOverlay = styled(CustomImage)`
	position: absolute;
	z-index: 2;
	top: 0;
`

export const WorkingDescription = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	align-items: center;
	margin-bottom: 10px;
`

export const WorkingNumber = styled.div`
	width: 24px;
	height: 24px;
	background: ${Gradient};
	margin-right: 10px;

	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 12px;
`
