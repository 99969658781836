import { HorizontalGroup, VerticalGroup } from "@saysom/shared"
import React from "react"
import { PLarge } from "../../../styles"
import {
	CompanyName,
	Icon,
	IconImg,
	MessageContainer,
} from "./recommendations_style"

const Recommendations = () => {
	return (
		<>
			<HorizontalGroup style={{ marginBottom: "40px", zIndex: 1 }}>
				<Icon
					href="https://www.vector.com/de/de/"
					target="_blank"
					rel="noreferrer"
				>
					<IconImg
						src="/assets/images/vector@2x.png"
						srcSet="/assets/images/vector@2x.png 2x,/assets/images/vector.png 1x"
						alt="Vector"
					/>
				</Icon>
				<VerticalGroup style={{ marginLeft: "10px" }} alignItems="flex-start">
					<a
						href="https://www.vector.com/de/de/"
						target="_blank"
						rel="noreferrer"
					>
						<CompanyName>Vector Informatik</CompanyName>
					</a>
					<MessageContainer left>
						<PLarge color="white" style={{ textAlign: "justify" }}>
							Finally, lasting networking in the digital space. The team behind
							SaySom supported us wonderfully during the event and in the
							preparation. Our participants were thrilled!
							<br />
							<br />
							<span className="font-bold">Thomas Wieland</span>
						</PLarge>
					</MessageContainer>
				</VerticalGroup>
			</HorizontalGroup>

			<HorizontalGroup style={{ marginBottom: "40px", zIndex: 1 }}>
				<VerticalGroup style={{ marginRight: "10px" }} alignItems="flex-end">
					<a href="https://www.personio.de" target="_blank" rel="noreferrer">
						<CompanyName>Personio</CompanyName>
					</a>
					<MessageContainer>
						<PLarge color="white" style={{ textAlign: "justify" }}>
							We at Personio used Saysom for our virtual HR experience, H.U.G
							Digital, with over 5500 attendees. The team at Saysom made it
							possible to easily integrate the networking solution into our
							Event Tool and to customise it to our Event! Thanks for supporting
							us all the time and providing the best possible user experience
							for our participants.
							<br />
							<br />
							<span className="font-bold">Helena Korff</span>
						</PLarge>
					</MessageContainer>
				</VerticalGroup>
				<Icon href="https://www.personio.de" target="_blank" rel="noreferrer">
					<IconImg
						src="/assets/images/personio@2x.png"
						srcSet="/assets/images/personio@2x.png 2x,/assets/images/personio.png 1x"
						alt="Personio"
					/>
				</Icon>
			</HorizontalGroup>

			<HorizontalGroup style={{ marginBottom: "40px", zIndex: 1 }}>
				<Icon
					href="https://houseofinnovation.io"
					target="_blank"
					rel="noreferrer"
				>
					<IconImg
						src="/assets/images/houseOfInnovation@2x.png"
						srcSet="/assets/images/houseOfInnovation@2x.png 2x,/assets/images/houseOfInnovation.png 1x"
						alt="HouseOfInnovation"
					/>
				</Icon>
				<VerticalGroup style={{ marginLeft: "10px" }} alignItems="flex-start">
					<a
						href="https://houseofinnovation.io"
						target="_blank"
						rel="noreferrer"
					>
						<CompanyName left>houseofinnovation.io</CompanyName>
					</a>
					<MessageContainer left>
						<PLarge color="white" style={{ textAlign: "justify" }}>
							Saysom is awesome! – playfull and a creativity booster! It finally
							reconnected us to our innovation community!
							<br />
							<br />
							<span className="font-bold">Mika Mänz</span>
						</PLarge>
					</MessageContainer>
				</VerticalGroup>
			</HorizontalGroup>

			<HorizontalGroup style={{ zIndex: 1 }}>
				<VerticalGroup style={{ marginRight: "10px" }} alignItems="flex-end">
					<a href="https://www.media-lab.de" target="_blank" rel="noreferrer">
						<CompanyName>Media Lab Bayern</CompanyName>
					</a>
					<MessageContainer>
						<PLarge color="white" style={{ textAlign: "justify" }}>
							Saysom gave the networking area at our digital community event its
							very own charm and combines fun and networking in one. This is how
							digital events can finally be fun again. At our digital community
							event everyone loved it!
							<br />
							<br />
							<span className="font-bold">Alicia Enenkel</span>
						</PLarge>
					</MessageContainer>
				</VerticalGroup>
				<Icon href="https://www.media-lab.de" target="_blank" rel="noreferrer">
					<IconImg
						src="/assets/images/mediaLab@2x.png"
						srcSet="/assets/images/mediaLab@2x.png 2x,/assets/images/mediaLab.png 1x"
						alt="Media Lab Bayern"
					/>
				</Icon>
			</HorizontalGroup>
		</>
	)
}

export default Recommendations
